import { Site } from '@apps/site';
import { PageBlocksFullImageVideo } from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import {
  getFontWeightClass,
  getHeadlineTag,
  getVariantHeightClass,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';

const ArrowIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6002 0.371094H17.6002V35.4111H19.6002V0.371094Z"
      className="fill-shapes-path"
    />
    <path
      d="M18.6002 37.0011L0.200195 18.5911L1.6102 17.1811L18.6002 34.1711L35.5902 17.1811L37.0002 18.5911L18.6002 37.0011Z"
      className="fill-shapes-path"
    />
  </svg>
);

export default function FullImageVideo({
  data,
  site,
}: {
  data: PageBlocksFullImageVideo & { homeButton?: boolean };
  site: Site;
}) {
  const HeadlineTag = getHeadlineTag(transformToHeadlineParams(data?.headline));
  const FontWeightClass = getFontWeightClass(
    transformToHeadlineParams(data?.headline),
  );

  const formattedMedia = data.media
    ?.replace(/ /g, '%20')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/=/g, '%3D');

  const isVideo = formattedMedia && formattedMedia.endsWith('.mp4');

  return (
    <section className="fullImageVideo overflow-hidden">
      <div
        className={`relative w-full ${getVariantHeightClass(data.variant)} flex flex-col justify-end`}
        data-tina-field={tinaField(data)}
        style={{
          maxWidth: '100%',
          aspectRatio: '428 / 926',
        }}
      >
        <div className="container h-full">
          {data.media && (
            <div
              className="absolute inset-0 z-0"
              style={{
                backgroundColor: 'black',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {isVideo ? (
                <video
                  className="w-full h-full object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={formattedMedia}
                />
              ) : (
                <div
                  className="w-full h-full bg-center bg-cover bg-no-repeat"
                  style={{ backgroundImage: `url(${formattedMedia})` }}
                />
              )}
            </div>
          )}

          <div
            className={`flex flex-col ${data.variant === 'Small' ? 'justify-end' : 'justify-between'} h-full`}
          >
            {!data.showBreadcrumb && (
              <div
                className="w-full relative text-white text-base flex items-center space-x-1 md:pt-[160px] pt-[120px]"
                data-tina-field={tinaField(data, 'breadcrumb')}
              ></div>
            )}

            {data.showBreadcrumb &&
              (data.variant === 'Large' ||
                data.variant === 'Screen Height') && (
                <div
                  className="w-full relative text-white text-base flex items-center space-x-1 md:pt-[160px] pt-[120px]"
                  data-tina-field={tinaField(data, 'breadcrumb')}
                >
                  {(data?.breadcrumb?.length || 0) > 0
                    ? data.breadcrumb?.map((item, index) => (
                        <span
                          key={`breadcrumb#${index}`}
                          className="flex items-center"
                        >
                          <SafeLink
                            href={item?.link || '#'}
                            className="hover:underline"
                          >
                            {item?.text || 'No text available'}
                          </SafeLink>
                          {index < (data?.breadcrumb?.length || 0) - 1 && (
                            <span className="px-1">/</span>
                          )}
                        </span>
                      ))
                    : 'Breadcrumbs generated automatically'}
                  {data.headline && (
                    <span className="flex items-center">
                      <span className="px-1">/</span>
                      <span>{data.headline?.text}</span>
                    </span>
                  )}
                </div>
              )}

            {/* Headline and Shape Options for Large and Small Variants */}
            <div
              className={`w-full relative contentGrid grid flex flex-col justify-between ${
                data.variant === 'Small'
                  ? 'md:pb-[80px] pb-[40px]'
                  : 'md:pb-[160px] pb-[120px]'
              }`}
            >
              {/* Headline Container: Only show for Large variant */}
              {(data.variant === 'Large' || data.variant === 'Screen Height') &&
                data.headline && (
                  <div
                    className={`relative ${data?.homeButton ? 'md:col-span-3' : 'md:col-span-1'} col-span-3 row-span-1 flex flex-col justify-center items-start py-4`}
                  >
                    <HeadlineTag
                      className={`${FontWeightClass} text-white break-words text-left`}
                      style={{
                        overflowWrap: 'break-word',
                      }}
                      data-tina-field={tinaField(data, 'headline')}
                    >
                      {data.headline?.text}
                    </HeadlineTag>
                  </div>
                )}

              {/* Shape Options Container: Positioned at the end of the second column */}
              {!data?.homeButton && (
                <div
                  className={`relative col-span-2 col-start-4 row-span-1 flex justify-end items-center md:col-span-1 md:col-start-2 lg:col-span-1 lg:col-start-2`}
                  data-tina-field={tinaField(data, 'shapeOptions')}
                >
                  <ShapesComponent shapeOptions={data.shapeOptions || {}} />
                </div>
              )}
              {data?.homeButton && (
                <div
                  className={`relative col-span-4 col-start-1 row-span-1 flex justify-end items-center md:col-span-1 md:col-start-4 lg:col-span-1 lg:col-start-4`}
                  data-tina-field={tinaField(data, 'shapeOptions')}
                >
                  <SafeLink
                    href={'/'}
                    className="button light tertiary-button h-11 w-full md:w-fit !bg-primary"
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingTop: '9px',
                      paddingBottom: '6px',
                      border: 'none',
                    }}
                  >
                    Return to Home
                  </SafeLink>
                </div>
              )}
            </div>
          </div>

          {/* Arrow for Large Variant (Desktop Only) */}
          {(data.variant === 'Large' || data.variant === 'Screen Height') &&
            data.arrow && (
              <div
                className="absolute hidden md:block col-span-1 row-span-1 bottom-[160px] right-0 p-4"
                data-tina-field={tinaField(data, 'arrow')}
              >
                <ArrowIcon />
              </div>
            )}
        </div>
      </div>
    </section>
  );
}

FullImageVideo.blockName = 'PageBlocksFullImageVideo';
